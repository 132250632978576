<template>
	<div class="pa-2">
		<v-row no-gutters>
			<AppButton
				label="app.create_observation"
				color="success"
				icon="plus"
				@click="createObservation()"
			></AppButton>
		</v-row>

		<v-row no-gutters class="mt-10">
			<table v-if="observations && observations.length" class="subtitle-1">
				<!-- Header row -->
				<tr>
					<th
						v-for="(header, index) in headers"
						:key="index"
						class="font-weight-bold"
					>
						{{ $t(header) }}
					</th>
				</tr>

				<!-- Data row -->
				<tr
					v-for="(interview, rowIndex) in observations"
					:key="rowIndex"
					align="center"
					:class="{ 'greyLighten4 ': (rowIndex + 1) % 2 == 0 }"
				>
					<td :style="{ width: '10%' }">{{ interview.id }}</td>
					<td>
						{{
							$route.params.type === "plan_interview"
								? interview.actual_date
								: interview.date
						}}
					</td>
					<td>{{ interview.interviewees.length }}</td>

					<td align="center" :style="{ width: '10%' }">
						<!-- Delete -->
						<v-btn small color="error" dark @click="delete_dialog = true">
							<v-icon small>
								$vuetify.icons.values.delete
							</v-icon>
						</v-btn>

						<!-- View -->
						<v-btn
							small
							dark
							color="info"
							class="mx-2"
							@click="exploreObservation(interview.id)"
						>
							<v-icon small>
								$vuetify.icons.values.eye
							</v-icon>
						</v-btn>
					</td>

					<!-- Confirmation box for delete operation -->
					<TheConfirmation
						v-model="delete_dialog"
						:dialog="delete_dialog"
						@cancel="delete_dialog = false"
						@confirm="deleteObservation(interview.id)"
					></TheConfirmation>
				</tr>
			</table>
		</v-row>
	</div>
</template>

<script>
import { mapState } from "vuex";
import TheConfirmation from "@/components/layout/TheConfirmation";

export default {
	name: "ObservationsList",

	data() {
		return {
			delete_dialog: false,
			headers: [],
			observations: null,
		};
	},

	components: {
		TheConfirmation,
	},

	mounted() {
		this.getObservations();
	},

	computed: {
		...mapState({
			// observations: (state) => state.observations.list,
		}),
	},

	methods: {
		async getObservations() {
			try {
				this.$loader.start();

				// await this.$store.dispatch("observation/list");
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		async createObservation() {
			try {
				this.$loader.start();

				// await this.$store.dispatch("observation/create");
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		exploreObservation(observation_id) {
			try {
				this.$loader.start();

				this.$router.push({
					name: "Observation",
					params: {
						id: observation_id,
					},
				});
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		deleteObservation(observation_id) {
			try {
				this.$loader.start();

				// Delete the Interview
				// this.$store.dispatch("observation/delete");

				// Close the dialog
				this.delete_dialog = false;
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},
	},
};
</script>
