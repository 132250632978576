<template>
	<div class="pa-2">
		<ObservationList></ObservationList>
	</div>
</template>

<script>
import ObservationList from "@/components/shared/tools/observation/List.vue";

export default {
	name: "ObservationListPage",

	components: {
		ObservationList,
	},
};
</script>
